/* TypeScript file generated from Web__Index.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as Web__IndexJS from './Web__Index.bs.js';

export type props = {};

export const $$default: React.ComponentType<{}> = Web__IndexJS.default as any;

export default $$default;
