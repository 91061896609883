// Generated by ReScript, PLEASE EDIT WITH CARE


var appPrefix = "decidr";

var organizationMenu = appPrefix + "-organization-menu";

var businessMenu = appPrefix + "-business-menu";

var connectionsMenu = appPrefix + "-connections-menu";

var channelsMenu = appPrefix + "-channels-menu";

var teamMembersMenu = appPrefix + "-team-members-menu";

var aiMembersMenu = appPrefix + "-ai-members-menu";

var channelPanePosition = appPrefix + "-chat-pane-position";

var stripeResourceId = appPrefix + "-stripe-resource-id";

var stripeDeepLinkUrl = appPrefix + "-stripe-redirect-url";

var dynamicMenu = appPrefix + "-dynamic-menu";

export {
  organizationMenu ,
  businessMenu ,
  connectionsMenu ,
  channelsMenu ,
  teamMembersMenu ,
  aiMembersMenu ,
  channelPanePosition ,
  stripeResourceId ,
  stripeDeepLinkUrl ,
  dynamicMenu ,
}
/* No side effect */
