// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App__Page from "../../page/App__Page.bs.js";
import * as App__Layout from "../../layout/App__Layout.bs.js";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App__Routing from "../../routing/App__Routing.bs.js";
import * as App__SignOut from "../../auth/signOut/App__SignOut.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Toast from "@tamagui/toast";
import * as App__RelayUtils from "../../utils/relay/App__RelayUtils.bs.js";
import * as Routing__Params from "../../../../routing/src/params/Routing__Params.bs.js";
import * as Routing__Router from "../../../../routing/src/router/Routing__Router.bs.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as App__ErrorMessage from "../../error/App__ErrorMessage.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Modal from "../../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as App__ErrorBoundary from "../../error/boundary/App__ErrorBoundary.bs.js";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Spinner from "../../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__TextButton from "../../../../primitives/src/button/Primitives__TextButton.bs.js";
import * as Primitives__DecidrImage from "../../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as Primitives__DecidrToast from "../../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__IconProps from "../../../../rescript-tamagui/src/icons/RescriptTamagui__IconProps.bs.js";
import * as App__LoggedInUser__WideMenu from "../../loggedInUser/wideMenu/App__LoggedInUser__WideMenu.bs.js";
import * as RescriptTamagui__Icon__Info from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Info.bs.js";
import * as App__DrawerOpenerWithLogotype from "../../logo/App__DrawerOpenerWithLogotype.bs.js";
import * as RescriptTamagui__Icon__Shield from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Shield.bs.js";
import * as Spaces__Invitation__Mutations from "../../../../spaces/src/invitation/Spaces__Invitation__Mutations.bs.js";
import * as RescriptTamagui__Icon__UserCheck2 from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__UserCheck2.bs.js";
import * as App__LoggedInUser__ConnectedOnboarding from "../../loggedInUser/onboarding/App__LoggedInUser__ConnectedOnboarding.bs.js";
import * as AppFeedPageAcceptInvitationModalQuery_graphql from "../../../../relay-codegen/generated/AppFeedPageAcceptInvitationModalQuery_graphql.bs.js";

var convertVariables = AppFeedPageAcceptInvitationModalQuery_graphql.Internal.convertVariables;

var convertResponse = AppFeedPageAcceptInvitationModalQuery_graphql.Internal.convertResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, AppFeedPageAcceptInvitationModalQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, AppFeedPageAcceptInvitationModalQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(AppFeedPageAcceptInvitationModalQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(AppFeedPageAcceptInvitationModalQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(AppFeedPageAcceptInvitationModalQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(AppFeedPageAcceptInvitationModalQuery_graphql.node, convertVariables);

function App__FeedPage$AcceptInvitationModal$AcceptInvitationButton(props) {
  var toast = props.toast;
  var onCompleted = props.onCompleted;
  var loggedInUserDataId = props.loggedInUserDataId;
  var invitationId = props.invitationId;
  var match = Spaces__Invitation__Mutations.useAcceptInvitation();
  var acceptInvitation = match[0];
  return JsxRuntime.jsx(Primitives__Button.make, {
              title: "Accept invite",
              style: props.style,
              onPress: (function (_e) {
                  acceptInvitation(invitationId, (function (message, reason) {
                          toast.error(message, reason);
                        }), (function (param) {
                          onCompleted(param.organization.id);
                        }), undefined, (function (store, param) {
                          var organization = param.organization;
                          var loggedInUserConnectionId = RelayRuntime.ConnectionHandler.getConnectionID(loggedInUserDataId, "AppLoggedInUserSharedMenuFragment_loggedInUser_organizations", undefined);
                          Core__Option.forEach(Caml_option.nullable_to_opt(store.get(loggedInUserConnectionId)), (function (loggedInUserConnection) {
                                  App__RelayUtils.Helpers.addUniqueEdgeToConnection(store, loggedInUserConnection, organization.dataId, "OrganizationEdge", "last");
                                }));
                        }), undefined);
                }),
              busy: match[1]
            });
}

function App__FeedPage$AcceptInvitationModal$InvitedToOrganization(props) {
  var match = props.organization;
  var name = match.name;
  var logo = match.logo;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_6")),
              orientation: "horizontal",
              style: {
                alignItems: "center"
              },
              children: [
                logo !== undefined ? JsxRuntime.jsx(Primitives__DecidrImage.make, {
                        src: logo.url,
                        size: {
                          TAG: "Square",
                          _0: Primitives__Styling.dp(76)
                        },
                        style: {
                          borderRadius: Primitives__Styling.radiusDp(4)
                        },
                        alt: Core__Option.getOr(logo.alt, name + " logo")
                      }) : JsxRuntime.jsx(Primitives__Group.make, {
                        style: {
                          backgroundColor: Primitives__Styling.color("neutral_6"),
                          borderRadius: Primitives__Styling.radiusDp(4),
                          alignItems: "center",
                          height: Caml_option.some(Primitives__Styling.dp(76)),
                          justifyContent: "center",
                          width: Caml_option.some(Primitives__Styling.dp(76))
                        },
                        children: Caml_option.some(JsxRuntime.jsx(RescriptTamagui__Icon__Shield.make, {
                                  size: Caml_option.some(RescriptTamagui__IconProps.Size.dp(40))
                                }))
                      }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                fontWeight: 500
                              },
                              kind: "body1",
                              children: Caml_option.some(name)
                            }),
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              children: "Member",
                              color: Primitives__Styling.color({
                                    NAME: "text",
                                    VAL: {
                                      NAME: "on_surface",
                                      VAL: "subdued"
                                    }
                                  })
                            })
                      ]
                    })
              ]
            });
}

function App__FeedPage$AcceptInvitationModal$ContentContainer(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("ss_4")),
              style: {
                padding: Caml_option.some(Primitives__Styling.space("cs_6"))
              },
              children: Caml_option.some(props.children)
            });
}

function App__FeedPage$AcceptInvitationModal$LoggedInAs(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              orientation: "horizontal",
              style: {
                alignItems: "center"
              },
              children: [
                JsxRuntime.jsx(Primitives__Icon.make, {
                      size: "ic_3",
                      color: Primitives__Styling.color("neutral_2"),
                      icon: RescriptTamagui__Icon__UserCheck2.make
                    }),
                JsxRuntime.jsxs(Primitives__DecidrText.make, {
                      children: [
                        "Logged in as ",
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                fontWeight: 500
                              },
                              children: Caml_option.some(props.email)
                            })
                      ]
                    })
              ]
            });
}

function App__FeedPage$AcceptInvitationModal$ConnectedAccountsNotice(props) {
  var toast = props.toast;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      kind: "body3",
                      children: "With Connected Accounts, you can safely accept an organization invitation from another account (email). This allows you to manage all your organizations in one place for convenience. Please note that you can disconnect account at any time and connect it to another sign up if needed.",
                      color: Primitives__Styling.color({
                            NAME: "text",
                            VAL: {
                              NAME: "on_surface",
                              VAL: "subdued"
                            }
                          })
                    }),
                JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                      children: JsxRuntime.jsxs(Primitives__Group.make, {
                            gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                            orientation: "horizontal",
                            style: {
                              alignItems: "center"
                            },
                            children: [
                              JsxRuntime.jsx(Primitives__Icon.make, {
                                    size: "ic_2",
                                    color: Primitives__Styling.color({
                                          NAME: "text",
                                          VAL: {
                                            NAME: "on_surface",
                                            VAL: "subdued"
                                          }
                                        }),
                                    icon: RescriptTamagui__Icon__Info.make
                                  }),
                              JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      fontWeight: 500
                                    },
                                    kind: "body3",
                                    children: "Learn more about Connected Accounts",
                                    color: Primitives__Styling.color({
                                          NAME: "text",
                                          VAL: {
                                            NAME: "on_surface",
                                            VAL: "subdued"
                                          }
                                        })
                                  })
                            ]
                          }),
                      style: {
                        alignSelf: "flex-start"
                      },
                      onPress: (function (_e) {
                          toast.show("Not implemented", {
                                duration: 3000
                              });
                        })
                    })
              ]
            });
}

function App__FeedPage$AcceptInvitationModal$ErrorAutoCloseModal(props) {
  var onClose = props.onClose;
  React.useLayoutEffect(function () {
        onClose();
      });
  return null;
}

function App__FeedPage$AcceptInvitationModal$ConnectedContent(props) {
  var toast = props.toast;
  var onCompleted = props.onCompleted;
  var invitationId = props.invitationId;
  var onClose = props.onClose;
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use({
        invitationId: invitationId,
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  var invitation = match$1.organizationMemberHumanInvitation;
  var loggedInUser = match$1.loggedInUser;
  if (!isAuthenticated) {
    return JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$ErrorAutoCloseModal, {
                onClose: onClose
              });
  }
  if (loggedInUser === undefined) {
    return JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$ErrorAutoCloseModal, {
                onClose: onClose
              });
  }
  if (loggedInUser.__typename === "LoggedInUser") {
    var loggedInUserEmail = loggedInUser.personalOrganization.loggedInOrganizationMember.organizationMember.email;
    var loggedInUserDataId = loggedInUser.__id;
    var exit = 0;
    if (loggedInUserEmail !== undefined) {
      if (invitation !== undefined) {
        var invitationEmail = invitation.email;
        if (loggedInUserEmail !== invitationEmail) {
          return JsxRuntime.jsxs(App__FeedPage$AcceptInvitationModal$ContentContainer, {
                      children: [
                        JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$LoggedInAs, {
                              email: loggedInUserEmail
                            }),
                        JsxRuntime.jsxs(Primitives__Group.make, {
                              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                              children: [
                                JsxRuntime.jsxs(Primitives__DecidrText.make, {
                                      children: [
                                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                                              style: {
                                                fontWeight: 500
                                              },
                                              children: Caml_option.some(invitationEmail)
                                            }),
                                        " is invited to join:"
                                      ]
                                    }),
                                JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$InvitedToOrganization, {
                                      organization: invitation.organization
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$ConnectedAccountsNotice, {
                              toast: toast
                            }),
                        JsxRuntime.jsxs(Primitives__Group.make, {
                              gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                              style: {
                                alignItems: "center"
                              },
                              children: [
                                JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$AcceptInvitationButton, {
                                      invitationId: invitationId,
                                      loggedInUserDataId: loggedInUserDataId,
                                      onCompleted: onCompleted,
                                      toast: toast
                                    }),
                                JsxRuntime.jsx(Primitives__TextButton.make, {
                                      title: "Sign in with another account",
                                      onPress: (function (param) {
                                          App__SignOut.signOut();
                                        })
                                    })
                              ]
                            })
                      ]
                    });
        }
        exit = 2;
      }
      
    } else {
      exit = 2;
    }
    if (exit === 2 && invitation !== undefined) {
      return JsxRuntime.jsxs(App__FeedPage$AcceptInvitationModal$ContentContainer, {
                  children: [
                    JsxRuntime.jsxs(Primitives__Group.make, {
                          gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                          children: [
                            JsxRuntime.jsx(Primitives__DecidrText.make, {
                                  children: "You have been invited to join:"
                                }),
                            JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$InvitedToOrganization, {
                                  organization: invitation.organization
                                })
                          ]
                        }),
                    JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$AcceptInvitationButton, {
                          invitationId: invitationId,
                          loggedInUserDataId: loggedInUserDataId,
                          onCompleted: onCompleted,
                          style: {
                            alignSelf: "center"
                          },
                          toast: toast
                        })
                  ]
                });
    }
    
  } else if (invitation !== undefined) {
    return JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$ErrorAutoCloseModal, {
                onClose: onClose
              });
  }
  return JsxRuntime.jsxs(App__FeedPage$AcceptInvitationModal$ContentContainer, {
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        textAlign: "center"
                      },
                      kind: "body1",
                      children: "Invitation doesn't exist anymore"
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: "Go to app",
                      style: {
                        alignSelf: "center"
                      },
                      onPress: (function (_e) {
                          onClose();
                        })
                    })
              ]
            });
}

function App__FeedPage$AcceptInvitationModal(props) {
  var onClose = props.onClose;
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Toast.ToastProvider, {
                    children: JsxRuntime.jsx(Primitives__Suspense.make, {
                          children: JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal$ConnectedContent, {
                                onClose: onClose,
                                invitationId: props.invitationId,
                                onCompleted: props.onCompleted,
                                toast: props.toast
                              })
                        })
                  }),
              show: props.show,
              onClose: onClose,
              style: {
                paddingTop: Caml_option.some(Primitives__Styling.dp(0))
              },
              maxWidth: 480
            });
}

function App__FeedPage$Content(props) {
  return JsxRuntime.jsx(App__ErrorBoundary.make, {
              debugContext: "App__FeedPage",
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                              style: {
                                backgroundColor: Primitives__Styling.color({
                                      NAME: "surface",
                                      VAL: "default"
                                    }),
                                flex: 1,
                                paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3")),
                                paddingTop: Caml_option.some(Primitives__Styling.space("ss_2"))
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                                        style: {
                                          fontWeight: 500,
                                          textAlign: "center"
                                        },
                                        kind: "title3",
                                        children: "Welcome to Decidr!"
                                      }))
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {}))
                  }),
              FallbackComponent: (function (param) {
                  return JsxRuntime.jsx(App__ErrorMessage.make, {
                              error: param.errorMessage
                            });
                })
            });
}

var Content = {
  make: App__FeedPage$Content
};

function App__FeedPage(props) {
  var router = Routing__Router.useRouter();
  var match = Routing__Params.useString(App__Routing.Constants.Feed.inviteId);
  var setInvitationId = match[1];
  var invitationId = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowInvitationModal = match$1[1];
  var showInvitationModal = match$1[0];
  var toast = Primitives__DecidrToast.use();
  React.useEffect((function () {
          if (invitationId !== undefined && !showInvitationModal) {
            setShowInvitationModal(function (param) {
                  return true;
                });
          }
          
        }), [
        invitationId,
        showInvitationModal
      ]);
  var handleCloseInvitationModal = function () {
    setInvitationId(undefined, {
          webBehavior: "replace"
        });
    setShowInvitationModal(function (param) {
          return false;
        });
  };
  var handleLogoPress = function () {
    App__Routing.push(router, "Feed", undefined, {
          shallow: true
        });
  };
  var handleOrganizationPress = function (organizationId) {
    App__Routing.push(router, {
          TAG: "OrganizationFeed",
          id: organizationId
        }, undefined, {
          shallow: true
        });
  };
  return JsxRuntime.jsx(App__Page.make, {
              children: JsxRuntime.jsx(App__ErrorBoundary.make, {
                    debugContext: "App__FeedPage App__LoggedInUser__ConnectedOnboarding",
                    children: JsxRuntime.jsxs(App__LoggedInUser__ConnectedOnboarding.make, {
                          children: [
                            JsxRuntime.jsx(App__Layout.make, {
                                  narrowPrimaryMenu: (function (param) {
                                      return null;
                                    }),
                                  renderWidePrimaryMenu: (function (param) {
                                      return JsxRuntime.jsx(App__LoggedInUser__WideMenu.Connected.make, {
                                                  onLogoPress: handleLogoPress,
                                                  onOrganizationPress: handleOrganizationPress,
                                                  onSwitchIconPress: undefined
                                                });
                                    }),
                                  primaryContent: JsxRuntime.jsx(Primitives__Suspense.make, {
                                        children: JsxRuntime.jsx(App__FeedPage$Content, {})
                                      }),
                                  renderHeaderLeftForNonLarge: (function (openDrawer) {
                                      return JsxRuntime.jsx(App__DrawerOpenerWithLogotype.make, {
                                                  onMenuPress: openDrawer
                                                });
                                    })
                                }),
                            JsxRuntime.jsx(App__ErrorBoundary.make, {
                                  debugContext: "App__FeedPage AcceptInvitationModal",
                                  children: invitationId !== undefined ? JsxRuntime.jsx(App__FeedPage$AcceptInvitationModal, {
                                          show: showInvitationModal,
                                          onClose: handleCloseInvitationModal,
                                          invitationId: invitationId,
                                          onCompleted: (function (organizationId) {
                                              App__Routing.replace(router, {
                                                    TAG: "OrganizationFeed",
                                                    id: organizationId
                                                  }, undefined, undefined);
                                            }),
                                          toast: toast
                                        }) : null,
                                  FallbackComponent: (function (param) {
                                      return null;
                                    })
                                })
                          ]
                        }),
                    FallbackComponent: (function (param) {
                        return JsxRuntime.jsx(App__ErrorMessage.make, {
                                    error: param.errorMessage
                                  });
                      })
                  })
            });
}

var make = App__FeedPage;

export {
  Content ,
  make ,
}
/* use Not a pure module */
