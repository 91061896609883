// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Web__Utils from "../utils/Web__Utils.bs.js";
import * as App__FeedPage from "../../../../packages/app/src/pages/feed/App__FeedPage.bs.js";
import * as App__StripeHooks from "../../../../packages/app/src/stripe/App__StripeHooks.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptNext__Head from "../../../../packages/rescript-next/src/head/RescriptNext__Head.bs.js";
import * as App__FatalErrorBoundary from "../../../../packages/app/src/error/boundary/App__FatalErrorBoundary.bs.js";

function Web__Index(props) {
  App__StripeHooks.useConnectStripeAccount();
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(RescriptNext__Head.make, {
                      children: [
                        JsxRuntime.jsx("title", {
                              children: Web__Utils.makePageTitleElement("Home")
                            }),
                        JsxRuntime.jsx("meta", {
                              content: "The official Decidr app",
                              name: "description"
                            }),
                        JsxRuntime.jsx("link", {
                              href: "/favicon.ico",
                              rel: "icon"
                            })
                      ]
                    }),
                JsxRuntime.jsx(App__FatalErrorBoundary.make, {
                      children: JsxRuntime.jsx(App__FeedPage.make, {})
                    })
              ]
            });
}

var make = Web__Index;

var $$default = Web__Index;

export {
  make ,
  $$default as default,
}
/* Web__Utils Not a pure module */
