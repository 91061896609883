// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App__Logotype from "./App__Logotype.bs.js";
import * as Primitives__Icon from "../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__UnstyledButton from "../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__Icon__Menu from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Menu.bs.js";

function App__DrawerOpenerWithLogotype(props) {
  var onMenuPress = props.onMenuPress;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              orientation: "horizontal",
              style: {
                alignItems: "center"
              },
              children: [
                JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                      children: JsxRuntime.jsx(Primitives__Icon.make, {
                            size: "ic_4",
                            icon: RescriptTamagui__Icon__Menu.make
                          }),
                      onPress: (function (param) {
                          onMenuPress();
                        })
                    }),
                JsxRuntime.jsx(App__Logotype.make, {})
              ]
            });
}

var make = App__DrawerOpenerWithLogotype;

export {
  make ,
}
/* App__Logotype Not a pure module */
