// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as $$Storage from "../../../storage/src/Storage.bs.js";
import * as App__Config from "../config/App__Config.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as App__UrlUtils from "../utils/url/App__UrlUtils.bs.js";
import * as ErrorReporting from "../../../error-reporting/src/ErrorReporting.bs.js";
import * as Routing__Params from "../../../routing/src/params/Routing__Params.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as RescriptSolito__Router from "../../../rescript-solito/src/router/RescriptSolito__Router.bs.js";
import * as App__LocalStorage__Keys from "../localStorage/App__LocalStorage__Keys.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as AppStripeHooks_connectStripeAccountMutation_graphql from "../../../relay-codegen/generated/AppStripeHooks_connectStripeAccountMutation_graphql.bs.js";
import * as AppStripeHooks_createStripeOAuthLinkMutation_graphql from "../../../relay-codegen/generated/AppStripeHooks_createStripeOAuthLinkMutation_graphql.bs.js";

var convertVariables = AppStripeHooks_createStripeOAuthLinkMutation_graphql.Internal.convertVariables;

var convertResponse = AppStripeHooks_createStripeOAuthLinkMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = AppStripeHooks_createStripeOAuthLinkMutation_graphql.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, AppStripeHooks_createStripeOAuthLinkMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, AppStripeHooks_createStripeOAuthLinkMutation_graphql.node, convertResponse, convertWrapRawResponse);

var CreateStripeOAuthLinkMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

var convertVariables$1 = AppStripeHooks_connectStripeAccountMutation_graphql.Internal.convertVariables;

var convertResponse$1 = AppStripeHooks_connectStripeAccountMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = AppStripeHooks_connectStripeAccountMutation_graphql.Internal.convertWrapRawResponse;

var commitMutation$1 = RescriptRelay_Mutation.commitMutation(convertVariables$1, AppStripeHooks_connectStripeAccountMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, AppStripeHooks_connectStripeAccountMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var ConnectStripeAccountMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation$1,
  use: use$1
};

function useCreateStripeOAuthLink() {
  var match = use();
  var createStripeOAuthLink = match[0];
  var currentPath = App__UrlUtils.useCurrentPath();
  var handleCreateStripeOAuthLink = function (onError, onCompleted, resourceId, param) {
    createStripeOAuthLink({
          redirectUrl: "https://" + App__Config.values.webRootUrl
        }, undefined, undefined, undefined, (function (param, errors) {
            var link = param.createStripeOAuthLink.link;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating Stripe OAuth link", "App__StripeHooks useCreateStripeOAuthLink", (function (message, param) {
                    onError(message);
                  }), (function () {
                    $$Storage.setString(App__LocalStorage__Keys.stripeResourceId, resourceId);
                    if (currentPath !== "") {
                      $$Storage.setString(App__LocalStorage__Keys.stripeDeepLinkUrl, currentPath);
                    }
                    onCompleted(link);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating Stripe OAuth link", "App__StripeHooks useCreateStripeOAuthLink", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleCreateStripeOAuthLink,
          match[1]
        ];
}

function useConnectStripeAccount() {
  var match = use$1();
  var connectStripeAccount = match[0];
  var match$1 = Routing__Params.useString("code");
  var setAuthCode = match$1[1];
  var authCode = match$1[0];
  var match$2 = Routing__Params.useString("scope");
  var setScope = match$2[1];
  var scope = match$2[0];
  var navigation = RescriptSolito__Router.useRouter();
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  React.useEffect((function () {
          if (authCode !== undefined && scope !== undefined) {
            Core__Option.forEach($$Storage.getString(App__LocalStorage__Keys.stripeResourceId), (function (resourceId) {
                    connectStripeAccount({
                          authorizationCode: authCode,
                          organizationId: resourceId
                        }, undefined, undefined, undefined, (function (param, errors) {
                            var organizationId = param.connectStripeAccount.organizationId;
                            ErrorHandling__RelayMutationCompleted.handle(errors, "Error connecting Stripe account", "App__StripeHooks useConnectStripeAccount", (function (message, param) {
                                    setError(function (param) {
                                          return message;
                                        });
                                  }), (function () {
                                    ErrorReporting.captureLog("Connecting stripe account data", "App__StripeHooks useConnectStripeAccount", {
                                          organizationId: organizationId
                                        });
                                    $$Storage.deleteKey(App__LocalStorage__Keys.stripeResourceId);
                                    Core__Option.forEach($$Storage.getString(App__LocalStorage__Keys.stripeDeepLinkUrl), (function (redirectUrl) {
                                            $$Storage.deleteKey(App__LocalStorage__Keys.stripeDeepLinkUrl);
                                            if (redirectUrl !== "") {
                                              return RescriptSolito__Router.replace(navigation, redirectUrl, undefined, undefined, undefined);
                                            }
                                            
                                          }));
                                    setAuthCode(undefined, undefined);
                                    setScope(undefined, undefined);
                                  }), undefined);
                          }), (function (errors) {
                            ErrorHandling__RelayMutationError.handle(errors, "Error connecting Stripe account", "App__StripeHooks useConnectStripeAccount", (function (message, param) {
                                    setError(function (param) {
                                          return message;
                                        });
                                  }));
                          }), undefined);
                  }));
          }
          
        }), [
        authCode,
        scope
      ]);
  return [
          match[1],
          match$3[0]
        ];
}

export {
  CreateStripeOAuthLinkMutation ,
  ConnectStripeAccountMutation ,
  useCreateStripeOAuthLink ,
  useConnectStripeAccount ,
}
/* commitMutation Not a pure module */
